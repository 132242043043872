<template>
  <div class="info-img">
    <Navigation />
    <main>
      <div class="top">
        <img src="~img/strategy/03.jpeg">
      </div>
      <div class="content">
        <div class="name clearfix">{{address.name}}</div>
        <div class="pic-main-tel"> {{address.tel}} </div>
        <ul>
          <li @click="bgImg(item.image,index)" v-for="(item,index) in list" :key="item.id">
            <img :src="item.image">
            <p>{{item.name}}</p>
          </li>

        </ul>
      </div>
    </main>
    <el-dialog :visible.sync="dialogVisible" width="50%">
      <div class="box">
        <img :src="image">
        <img src="~img/strategy/06.png" class="left" @click="changeLeft">
        <img src="~img/strategy/05.png" class="right" @click="changeRight">
      </div>
    </el-dialog>
    <consulting />
    <foot />
  </div>
</template>

<script>
import Navigation from "@/components/header.vue";
import "element-ui/lib/theme-chalk/display.css";
import footer from "@/components/footer.vue";
import consulting from "@/components/consulting.vue";
export default {
  components: {
    Navigation,
    foot: footer,
    consulting,
  },
  data() {
    return {
      dialogVisible: false,
      id: "",
      address: "",
      list: [],
      image: "",
      index: "",
    };
  },
  created() {
    this.address = JSON.parse(localStorage.getItem("address"));
    this.id = this.getQueryValue("id");
    this.getInfo();
    console.log(this.address);
  },
  methods: {
    changeLeft() {
      if (this.index <= 0) {
        this.index = 0;
      } else {
        this.index = this.index - 1;
      }
      this.image = this.list[this.index].image;
    },
    changeRight() {
      if (this.index >= this.list.length - 1) {
        this.index = this.list.length - 1;
      } else {
        this.index = this.index + 1;
      }
      this.image = this.list[this.index].image;
    },
    async getInfo() {
      const res = await this.axios.get(`api/index/mapShow?map_id=${this.id}`);
      if (res.status == 200 && res.data.code == 1) {
        this.list = res.data.data;
        console.log(this.list.length);
      }
    },
    bgImg(img, index) {
      this.dialogVisible = true;
      console.log(img, index);
      this.image = img;
      this.index = index;
    },
  },
};
</script>


<style lang="less" scoped>
.info-img {
  main {
    .top {
      padding-top: 0.3rem;
      img {
        width: 65%;
        margin: auto;
      }
    }
    .content {
      width: 65%;
      padding: 0.15rem;
      border: 0.01rem solid #bfbfbf;
      border-radius: 0.1rem;
      margin: auto;
      margin-top: 0.5rem;
      margin-bottom: 0.4rem;
      .name {
        font-size: 0.18rem;
        color: #0e0e0e;
        font-weight: 400;
        float: left;
      }
      .pic-main-tel {
        font-size: 0.18rem;
        color: #0e0e0e;
        font-weight: 400;
        float: left;
        background: url("~img/strategy/04.png") no-repeat left center;
        margin-left: 0.15rem;
        text-indent: 0.3rem;
      }
      ul {
        margin-top: 0.5rem;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        li {
          width: 24%;
          text-align: center;
          font-size: 0.18rem;
          color: #777777;
          margin-top: 0.1rem;
          cursor: pointer;
          margin-right: 0.2rem;
        }
      }
    }
  }
  /deep/.el-dialog {
    border-radius: 0.1rem;
    .box {
      position: relative;
      .left {
        width: 0.34rem;
        position: absolute;
        top: 50%;
        left: -1rem;
        transform: translate(0, -50%);
        cursor: pointer;
      }
      .right {
        width: 0.34rem;
        position: absolute;
        top: 50%;
        right: -1rem;
        cursor: pointer;
        transform: translate(0, -50%);
      }
    }
  }
}
</style>